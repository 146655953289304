import { Toast } from "bootstrap";

export const Notification = (function () {
  const showNegativeMessage = (message) => {
    document.getElementById('notification__negative--message').innerHTML = message;
    Toast.getOrCreateInstance(document.getElementById('notification__negative')).show();
  }

  const showPositiveMessage = (message) => {
    document.getElementById('notification__positive--message').innerHTML = message;
    Toast.getOrCreateInstance(document.getElementById('notification__positive')).show();
  }

  const show = function ({type, message}) {
    console.log(type)
    if (type === 'negative') {
      return showNegativeMessage(message)
    }

    return showPositiveMessage(message);
  }

  return {
    show,
  }
})();
