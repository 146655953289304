import {API_ROOT, APP_URL} from "./constants";
import {currentPage, Page} from "./utils";

function getQuote(cityId) {
  window.location.href = `${APP_URL}/rfq?cityId=${cityId}`;
}

class Home {
  constructor() {
    this.cities = [];
    this.livePorts = ['sydney', 'melbourne'];
    this.citySelect = document.querySelector('.city__select');
  }

  async initialize() {
    if (currentPage() !== Page.Home) return;

    await this.syncData();
    this.addEventListeners();
    this.handleOnboardingAlert();
  }
  async syncData() {
    await this.getCities();

    // Build port city selector
    /*Start city select*/
    while (this.citySelect.options.length > 1) {
      this.citySelect.options.remove(1);
    }

    this.cities.forEach(city => {
      const option = new Option(city.name, city.id);
      option.disabled = !this.livePorts.includes(city.slug);
      this.citySelect.options.add(option);
    })
    /* End city select */

    // Add style and event to the live port
    this.livePorts.map(name => {
      const selector = `#port-${name}`;
      const city = this.cities.find(city => city.slug === name);
      const element = document.querySelector(selector);

      if(element && city) {
        element.style.cursor = 'pointer';
        element.addEventListener('click', function (){
          getQuote(city.id);
        });
      }
    })
  }

  getCities() {
    return fetch(`${API_ROOT}/city/basic?perPage=20`)
      .then(response => response.json())
      .then(body => this.cities = body.data);
  }

  addEventListeners() {
    // Get quote btn
    document.querySelector('.quote__btn')
      .addEventListener('click', () => {
        const cityId = this.citySelect.value;
        if(cityId) {
          getQuote(cityId);
        }
      })
  }

  handleOnboardingAlert() {
    const closedAlert = sessionStorage.getItem('closedAlert');

    if(!closedAlert) {
      const element = document.getElementById('onboarding-alert');
      element.addEventListener('closed.bs.alert', function () {
        sessionStorage.setItem('closedAlert', 'true');
      })
      element.classList.add('show');
    }
  }
}

const instance = new Home();

export default instance;


