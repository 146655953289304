export const resetForm = (selector) => {
  const form = document.querySelector(selector);
  if(form) {
    for(let i = 0; i < form.elements.length; i ++) {
      const element = form.elements[i];
      if(element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
        element.value = '';
      }

      if(element.tagName === 'SELECT') {
        element.selectedIndex = 0;
      }
    }

    form.classList.remove('was-validated');
  }
}

export const Page = {
  Home: 'home',
  Shipper: 'shipper',
  Transporter: 'transporter',
  Help: 'help',
  ContactUs: 'contact-us',
  Terms: 'terms',
}

export const currentPage = () => {
  return document.getElementsByTagName('body')[0].id;
}
