import {currentPage, Page as Screen} from "./utils";

const Page = {
  TermsAndConditions: 'terms-and-conditions',
  TermsOfUse: 'terms-of-use',
  AcceptableUsePolicy: 'acceptable-use-policy',
  CancellationPolicy: 'cancellation-policy'
}



const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0;
}


class Terms {
  constructor() {
    this.scrollButton = document.getElementById('terms__scroll');
    this.pageList = document.getElementById('terms__page-list');
    this.currentPage = '';
  }

  initialize() {
    if(currentPage() !== Screen.Terms) return;

    console.log('Initial term page')
    this.jumpPage(Page.TermsAndConditions)

    this.addEventListeners();
  }

  jumpPage(pageId) {
    if(this.currentPage === pageId) return;

    this.currentPage = pageId;

    document.querySelectorAll('.terms__page').forEach(page => page.classList.add('d-none'));
    const page = document.getElementById(pageId);
    if(page) {
      page.classList.remove('d-none');
    }

    this.handlePageChanged();
  }

  addEventListeners() {
    document.querySelectorAll('[data-jump-page]')
      .forEach(element => {
        element.addEventListener('click', ({ currentTarget }) => {
          const pageId = currentTarget.getAttribute('data-jump-page');
          this.jumpPage(pageId);
        })
      });

    this.scrollButton.addEventListener('click', scrollToTop)

    window.onscroll = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        this.scrollButton.style.display = "block";
      } else {
        this.scrollButton.style.display = "none";
      }
    }
  }
  handlePageChanged() {
    scrollToTop();
    for (let i = 0; i < this.pageList.children.length; i++) {
      const element = this.pageList.children[i];
      const pageId = element.getAttribute('data-jump-page');
      if(pageId === this.currentPage) {
        element.classList.add('current-page');
      } else {
        element.classList.remove('current-page');
      }
    }
  }

}

export default new Terms();
