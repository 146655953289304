import {API_ROOT} from "./constants";

function autocomplete(inp, searchUrl) {

  let currentFocus;
  let delayTimer;

  inp.addEventListener('input', function (e) {
    /* Close any already open lists of autocompleted values */
    closeAllLists();
    currentFocus = -1;

    clearTimeout(delayTimer);
    delayTimer = setTimeout(async () => {
      const helps = await fetch(searchUrl + this.value).then(response => response.json());

      const options = renderOptions(this.id, helps);
      this.parentNode.appendChild(options);

    }, 1000);
  });

  inp.addEventListener('focus', async function (e) {
    clearTimeout(delayTimer);

    const helps = await fetch(searchUrl + this.value).then(response => response.json());

    const options = renderOptions(this.id, helps);
    this.parentNode.appendChild(options);
  });

  function renderOptions(inputId, arr) {
    /* create a DIV element that will contain the items */
    const list = document.createElement('DIV');
    list.setAttribute('id', inputId + 'autocomplete-list');
    list.setAttribute('class', 'autocomplete__options shadow')

    /* Create options */
    for (let i = 0; i < arr.length; i++) {
      /* create a div element starts with the same letters as the text field value: */
      const option = document.createElement('DIV');
      /* Make the matching letters bold: */
      option.innerHTML = `<div>${arr[i].title}</div>`;
      /* insert a input field that will hold the current item's value */
      option.innerHTML += `<input type="hidden" value="${arr[i].slug}">`;
      /* execute the function when someone clicks on the autocomplete text field */
      option.addEventListener('click', function (event) {
        /* insert the value for the autocomplete text field */
        const slug = this.getElementsByTagName('input')[0].value;
        window.location.href = `${window.location.origin}/help?post=${slug}`;

        /* Close the list autocomplete text field */
        closeAllLists();
      });

      list.appendChild(option);
    }
    return list;
  }

  function closeAllLists(element) {
    /* close all autocomplete lists in the document except the one passed as an arg */
    const x = document.getElementsByClassName("autocomplete__options");
    for (let i = 0; i < x.length; i++ ) {
      if(element !== x[i] && element !== inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }

  /*execute a function when  someone clicks in the document*/
  document.addEventListener('click', function (e) {
    closeAllLists(e.target);
  })
}

class HelpSearch {
  constructor() {
    this.searchUrl = `${API_ROOT}/help/search?q=`;
    this.searchInput = document.getElementById('hs__input');
  }

  initialize() {
    if(!this.searchInput) return;

    autocomplete(this.searchInput, this.searchUrl);
  }
}

const instance = new HelpSearch();

export default instance;


