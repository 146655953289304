import {API_ROOT, DATE_FORMAT} from "./constants";
import format from 'date-fns/format';
import {currentPage, Page} from "./utils";

function createElementWithClass(tag, _class) {
  const element = document.createElement(tag);
  element.setAttribute('class', _class);

  return element;
}

class Help {
  constructor() {
    this.topics = [];
    this.helpTopic = document.getElementsByClassName('help__topic')[0];
    this.helpArticle = document.getElementsByClassName('help__article')[0];
    this.post = document.getElementsByClassName('help__post')[0];
  }

  async initialize() {
    if(currentPage() !== Page.Help) return;

    await this.fetchTopics();

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if(params.article) {
      return this.generateHelpArticle(params.article)
    }

    if(params.post) {
      return this.generatePost(params.post);
    }

    return this.generateTopics();
  }

  async fetchTopics() {
    this.topics = await fetch(`${API_ROOT}/help/topics`)
      .then(response => response.json());
  }

  async generateTopics() {
    // remove all children
    this.helpTopic.innerHTML = '';

    /* create topic */
    for (let i = 0; i < this.topics.length; i++) {
      const slug = this.topics[i].slug;
      const topic = createElementWithClass('div', 'col-12 col-md-6 col-lg-4');
      const card = createElementWithClass('div', 'card shadow-lg mb-6 mb-md-8 lift lift-lg cursor-pointer');
      card.addEventListener('click', function () {

        window.location.href = window.location.pathname + '?article=' + slug;
      })

      const cardBody = createElementWithClass('div', 'card-body text-center');
      cardBody.innerHTML = `<img alt="..." class="mb-3" src="/assets/img/help/icon-${this.topics[i].slug}.png" style="height: 80px;">`;
      cardBody.innerHTML += `<h4 class="fw-bold">${this.topics[i].name}</h4>`;

      const entries = this.topics[i].articleIds.length;

      cardBody.innerHTML +=
        `<span class="badge rounded-pill bg-dark-soft">
          <span class="h6 text-uppercase">
            ${entries} ${entries > 1 ? 'entries': 'entry'}
          </span>
        </span>`;

      card.appendChild(cardBody);
      topic.appendChild(card);
      this.helpTopic.appendChild(topic);
    }
  }

  async generateHelpArticle(slug) {
    const helps = await fetch(`${API_ROOT}/help?topic=${slug}`)
      .then(response => response.json());
    const topic = this.topics.find((t) => t.slug === slug);
    const header = topic
      ? `<h2 class="fw-bold mb-0 mt-5">
            Help > ${topic.name}
          </h2>`
      : '<p class="mt-5">There are no questions in this topic</p>';

    const accordion = createElementWithClass('div', 'accordion px-5 shadow-light-lg');
    accordion.setAttribute('id', 'helpAccordion');
    const heading = createElementWithClass('div', 'accordion-item border-0');
    heading.innerHTML = `
      <div class="accordion-button">
        <div class="me-auto">
          <div>
            <a href="${window.location.pathname}" class="text-decoration-none bold d-flex align-items-center" style="width: 70px;">
              <span class="fe fe-chevron-left"></span>
              BACK
            </a>
          </div>
          <!-- Heading -->
          ${header}
        </div>
      </div>
    `;
    accordion.appendChild(heading);

    for (let i = 0; i < helps.length; i++) {
      const help = helps[i];

      const accordionItem = createElementWithClass('div', 'accordion-item ms-7');

      const button = createElementWithClass('div', 'accordion-button collapsed')
      button.setAttribute('role', 'button')
      button.setAttribute('data-bs-toggle', 'collapse')
      button.setAttribute('data-bs-target', `#help${i}`)
      button.setAttribute('aria-expanded', 'false')
      button.setAttribute('aria-controls', `help${i}`)
      button.innerHTML = `<span class="bold" id="help${i}Heading">${help.title}</span>`;

      const collapse = createElementWithClass('div', 'accordion-collapse collapse');
      collapse.setAttribute('id', `help${i}`)
      collapse.setAttribute('aria-labelledby', `help${i}Heading`);
      collapse.setAttribute('data-bs-parent', `#helpAccordion`);

      const body = createElementWithClass('div', 'accordion-body ps-7 fs-sm');
      body.innerHTML = help.content;
      /* TODO: innerHTML from data */

      collapse.appendChild(body);

      accordionItem.appendChild(button);
      accordionItem.appendChild(collapse);

      accordion.appendChild(accordionItem);
    }


    this.helpArticle.appendChild(accordion);
  }

  async generatePost(slug) {
    const post = await fetch(`${API_ROOT}/help/${slug}`)
      .then(response => response.json());

    const card = createElementWithClass('div', 'card shadow-light-lg p-5');
    const header = createElementWithClass('div', 'header');
    header.innerHTML = `
      <a href="${window.location.pathname}" class="d-flex align-items-center bold text-decoration-none" style="width: 70px;">
        <span class="fe fe-chevron-left"></span>
        BACK
      </a>
    `;
    const content = createElementWithClass('div', 'content mt-3');
    content.innerHTML = '<p class="mb-0">This help article does not exist</p>';

    if(post.id) {
      console.log(post)
      // return format(new Date(tmp), DATE_FORMAT);
      header.innerHTML = `
        <div class="header">
          <a href="${window.location.pathname}" class="d-flex align-items-center bold text-decoration-none" style="width: 70px;">
            <span class="fe fe-chevron-left"></span>
            BACK
          </a>
          <h2 class="mt-5">${post.title}</h2>
          <p class="text-muted fs-sm">Last updated: ${format(new Date(post.updatedAt), DATE_FORMAT)}</p>
        </div>
      `;
      content.innerHTML = post.content;
    }

    card.appendChild(header);
    card.appendChild(content);

    this.post.appendChild(card);
  }
}

const instance = new Help();

export default instance;


