import {API_ROOT} from "./constants";
import {currentPage, Page, resetForm} from "./utils";
import {Notification} from "./notification";

class ContactUs {
  constructor() {
    this.form = document.querySelector('.contact__form');
    this.sendButton = document.querySelector('.contact__send--btn');
    this.email = document.getElementById('contact__email');
    this.content = document.getElementById('contact__content');
  }

  initialize() {
    if(currentPage() !== Page.ContactUs) return;

    this.addEventListeners();
  }

  addEventListeners() {
    this.sendButton.addEventListener('click',  (e) => {
      if(this.form.checkValidity()) {
        this.sendRequest();
      }
      this.form.classList.add('was-validated')
    });

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
    })
  }

  sendRequest() {
    this.sendButton.classList.add('btn-loading');
    const data = {
      email: this.email.value,
      question: this.content.value,
    }

    fetch(`${API_ROOT}/help`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then((data) => {
        this.sendButton.classList.remove('btn-loading');
        if(data.success) {
          Notification.show({type: 'positive', message: 'Your request is sent.'});
          resetForm('.contact__form');
        }
      });
  }
}

const instance = new ContactUs();

export default instance;


