import {Modal} from "bootstrap";
import {resetForm} from "./utils";
import {API_ROOT} from "./constants";
import {Notification} from "./notification";

class Request {
  constructor() {
    this.modal = document.getElementById('modalRequestCallback');
    this.form = document.getElementById('request__form');
    this.submitButton = document.getElementById('request__submit');
  }

  initialize() {
    if(!this.form) return;

    $('#request__date').datepicker({
      templates: {
        leftArrow: '<i class="fe fe-chevron-left"></i>',
        rightArrow: '<i class="fe fe-chevron-right"></i>'
      },
      startDate: '0d',
      orientation: 'left auto',
      autoclose: true,
    });

    this.addEventListeners();
  }

  addEventListeners() {
    this.form.addEventListener('submit', function (e) {
      e.preventDefault();
    });

    this.modal.addEventListener('hidden.bs.modal', function () {
      resetForm('#request__form');
    })

    this.submitButton.addEventListener('click', async (e) => {
      if(this.form.checkValidity()) {
        await this.sendRequest();
      }
      this.form.classList.add('was-validated')
    })
  }

  async sendRequest() {
    this.submitButton.classList.add('btn-loading');
    let data = {};

    for (let i = 0; i < this.form.elements.length; i ++) {
      const element = this.form.elements[i];
      const name = element.name;
      if (name) {
        data[name] = element.value;
      }
    }

    fetch(`${API_ROOT}/help/request-callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        this.submitButton.classList.remove('btn-loading');

        if(data.success) {
          Notification.show({type: 'positive', message: 'Your request send successfully.'});
          return Modal.getOrCreateInstance(this.modal).hide();
        }

        return Notification.show({type: 'negative', message: 'Your request send failed.'});
      });
  }
}

const instance = new Request();
export default instance;
