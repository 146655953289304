import {APP_GTM, APP_URL, WEBPACK_DEV_SERVER_HOST, WEBPACK_DEV_SERVER_PORT} from "./constants.js";
import Home from "./home";
import Help from "./help";
import HelpSearch from "./help-search";
import ContactUs from "./contact-us";
import Request from "./request";
import Terms from "./terms";

function replaceAllAppUrl() {
  document
    .querySelectorAll('a[href*="__APP_URL__"]')
    .forEach((el) => {
      const path = el.dataset.path;
      el.href = `${APP_URL}/${path}`;
    });
}

document.addEventListener("DOMContentLoaded", async () => {
  replaceAllAppUrl();

  // HOME PAGE
  await Home.initialize();

  // HELP PAGE
  await Help.initialize();

  // HELP SEARCH COMPONENT
  HelpSearch.initialize();

  // CONTACT PAGE
  ContactUs.initialize();

  Request.initialize();

  // Terms and conditions
  Terms.initialize();

  // Handle local link
  if(WEBPACK_DEV_SERVER_PORT) {
    const routes = ['/shipper', '/transporter', '/disclaimer', '/privacy', '/terms', '/resources', '/help', '/about', '/contact-us', '/media', '/media/delivercontainers-set-to-launch-in-australia'];
    document.querySelectorAll('a')
      .forEach(el => {
          const url = new URL(el.href);
          if(WEBPACK_DEV_SERVER_HOST === url.origin && routes.includes(url.pathname)) {
            el.href = `${url.origin}${url.pathname}.html${url.search}`;
          }
      });
  }
})

/*GOOGLE TAG MANAGER*/
if(APP_GTM && APP_GTM.length > 5) {
  (function(w,d,s,l,i){
    w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', APP_GTM);
}
